<template>
  <v-container>
    <v-overlay :value="loading" :opacity="0">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
    <v-card class="mt-5"  v-show="validToken">
      <v-card-title>Finalizar Cadastro</v-card-title>
      <v-card-text>
        <v-text-field
                name="password"
                label="Digite sua senha atual"
                :type="showingPassword ? 'text' : 'password'"
                v-model="form.newPassword"
                :append-icon="`far ${showingPassword ? 'fa-eye-slash' : 'fa-eye'} grey--text`"
                @click:append="showingPassword = !showingPassword"
        />
        <Recaptcha :siteKey="captchaSiteKey" @verify="verify" @error="invalidate" @expired="invalidate" :reload="true"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer v-if="$resize && $mq.above(window.mobileSize)"/>
        <v-btn :block="$resize && $mq.below(window.mobileSize)" color="primary" :loading="busy" :disabled="canUpdate" @click="redefinePassword()">
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="mt-7" v-show="validToken !=null && !validToken">
      <v-card-title>Seu token expirou</v-card-title>
      <v-card-text class="pb-5">
        <p>Este token está expirado. Por segurança, o token é válido por 3 dias. <v-btn style="padding: 0" text small color="primary" @click="resendToken()">Clique aqui</v-btn> para enviar um novo token ao seu e-mail utilizado para envio deste link.</p>
      </v-card-text>
    </v-card>
    <v-card class="mt-7" v-show="tokenNotExists">
      <v-card-title>Token inválido</v-card-title>
      <v-card-text class="pb-5">
        <p>O seu token é inválido ou já foi utilizado. Caso tenha esquecido a senha,  <v-btn to="/redefinir-senha" style="padding: 0" text small color="primary">solicite aqui</v-btn> .</p>
      </v-card-text>
    </v-card>
    <template v-slot:loading>Carregando</template>
  </v-container>
</template>

<script>
  import {mapMutations, mapState} from "vuex";
  import Recaptcha from '@/components/recaptcha/index.vue';
  import {resendEmailToken, validateEmailToken, createUserWithExistingAccount} from '@/common/securityserver';

  export default {
    name: "AlerarSenha",
    components: { Recaptcha },
    data: () => ({
      busy: false,
      loading: false,
      validToken: null,
      tokenNotExists: false,
      invalidCredentials: true,
      showingPassword: false,
      form: {
        newPassword: "",
      },
    }),
    computed: {
      ...mapState(["window"]),
      canUpdate() {
        const formHasData = this.form.newPassword;

        return !(formHasData && !this.invalidCredentials);
      },
      captchaSiteKey(){
        return process.env.VUE_APP_CAPTCHA_SITE_KEY;
      }
    },
    methods: {
      ...mapMutations(['showmsg']),

      redefinePassword() {
        this.busy= true;

        let user = {
          password : this.form.newPassword
        }

        createUserWithExistingAccount(user).then( response => {
          this.$router.push({
            path: '/serviceLoginCallback',
            query: {callbackType: 'redirect'}
          });
        }).catch(error => {
            let messageErro = "Não foi possível criar usuário!"
            if(error.response.data.message && error.response.data.message  == 'Token has already expired')
              messageErro = "Token expirado. Solicite um novo token para cadastrar usuário"
            this.showmsg({
              text: messageErro,
              type: "error",
            })}
        ).finally(() => this.busy = false);
      },
      resendToken() {
        this.loading = true;
        resendEmailToken().then(response => {
          if(response.error){
            this.$store.commit('showmsg', { text: 'Não foi possível definir a senha!', type: "error" });
          }else{
            const msg = `Será enviado um e-mail para você com as instruções necessárias para definir sua senha.`;
            this.$store.commit('showmsg', { text: msg, type: "success" });
            this.$router.push('/serviceLogin');
          }
        }).catch(error => {
          this.showmsg({
            text: 'Não foi possível definir a senha!',
            type: "error",
          })}
        ).finally(() => this.loading = false);
      },
      verify() {
        this.invalidCredentials = false;
      },
      invalidate() {
        this.invalidCredentials = true;
      },
    },
    async beforeMount() {
      this.$store.commit('callbackServiceLogin', this.$route.query.callback);
      this.$store.commit('userRole', this.$route.query.userRole);
      this.$store.commit('appCode', this.$route.query.appCode);
      this.$store.commit('token', this.$route.query.token);

      console.log("userRole", this.$route.query.userRole)
      await validateEmailToken(this.$route.query.token)
          .then(res => { this.validToken = res })
          .catch(error => { this.tokenNotExists = true })
    }
  };
</script>
