var _vm = null

export default {
  strToBool(str) {
    return (str.toLowerCase() === 'false' ? false : true)
  },
  formatCNPJ(cnpj) {
    return cnpj ? cnpj
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5") : ''
  },
  formatCPF(cpf) {
    return cpf ? cpf
      .replace(/\D/g, '')
      .replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4") : ''
  },
  vueFormatDateToBR(date) {
    if (!date) return null;
    const pdate = date.substring(0, 10);
    const [year, month, day] = pdate.split("-");
    return day.padStart(2, '0') + '/' + month.padStart(2, '0') + '/' + year;
  },
  vueFormatDateFromBR(date) {
    if (!date) return null;
    const [day, month, year] = date.split("/");
    return year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
  },
  isMobile() {
    // aler(navigator.userAgent)
    return (/ANDROID|WEBOS|IPHONE|IPAD|IPOD|BLACKBERRY|IEMOBILE|OPERA MINI/i.test(navigator.userAgent.toUpperCase()))
  },
  isIosMobile() {
    // alert(navigator.userAgent)
    return (/IPHONE|IPAD|IPOD/i.test(navigator.userAgent.toUpperCase()))
  },
  //is PWA app installed
  isInStandaloneMode() {
    return (window.matchMedia('(display-mode: standalone)').matches) || (('standalone' in navigator) && (navigator.standalone))
  },
  link(name, params) {
    // _vm.$store.commit('toogleMainView', false)
    return new Promise(resolve => {
      var rota = {
        name: name
      }

      if (params != undefined)
        rota.params = params

      _vm.$router.push(rota)
      resolve()
      // var time = 750
      // setTimeout(() => {
      //   _vm.$store.commit('toogleMainView', true)
      // }, time);
    })
  },
  linkNewTab(url) {
    if (!url.includes('http')) {
      url = window.location.origin + '/' + url
    }
    // debugger
    if (this.isIosMobile()) {
      window.location.assign(url)
    } else {
      window.open(url, '_blank');
    }
  },
  removeAccents(str) {
    return str ? str.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : str
  },
  checkIndex() {
    var fromIndex = !!(_vm.$route.redirectedFrom && _vm.$route.redirectedFrom.includes("index."))
    return fromIndex
  },
  isRoute(routename) {
    // console.log(_vm.$route)
    return (_vm.$route && _vm.$route.name == routename)
  },
  vue() {
    return _vm
  },
  round(n, places) {
    return +(Math.round(n + "e+" + places) + "e-" + places)
  },
  breakSpace(str, breaker) {
    return str ? str.replace(" ", (breaker ? breaker : "<br />")) : str
  },
  install(Vue, config) {
    _vm = config.vm
    Vue.prototype.$util = this
  }
}
