<template>
    <v-footer absolute class="transparent pa-0" v-if="this.appFooterLogo">
        <v-col cols="12" class="text-center pa-0">
            <img :src="this.appFooterLogo"/>
        </v-col>
    </v-footer>
</template>

<script>
    export default {
        name: "footer",
        computed:{
            appFooterLogo(){
                return this.$store.state.footerLogo;
            }
        }
    }
</script>
