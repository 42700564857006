<template>
 <!-- :scroll-threshold="50" scroll-off-screen -->
  <v-app-bar color="primary" app class="white--text" v-if="hideToolbar">
<!--    <v-app-bar-nav-icon @click.stop="toggleMenul">-->
<!--      <v-icon class="white&#45;&#45;text">fas fa-bars</v-icon>-->
<!--    </v-app-bar-nav-icon>-->
    <v-toolbar-title class="text-truncate">
      {{title}}
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <span class>{{user.nome}}</span>
    <v-btn icon @click.stop="toggleMenur">
      <v-avatar v-if="user.img" size="40">
        <img avatar :src="user.img" />
      </v-avatar>
      <v-icon v-else>fas fa-user-circle</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
    import {mapMutations, mapGetters} from "vuex";

    export default {
        computed: {
            ...mapGetters(['user']),
            title() {
                return 'Account';
            },
            hideToolbar() {
                return this.user.nome  ? true : false;
            },
        },
        methods: {
            ...mapMutations(["toggleMenul", "toggleMenur"]),
        },
    };
</script>
