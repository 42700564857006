import Vue from 'vue';

import VueRouter from 'vue-router';
import $store from '../store/store'
Vue.use(VueRouter)

import login from './../view/app/login';
import portal from './../view/app/portal';
import notfound from './../view/app/notfound';
import home from "./../view/home/home";
import PrimeiroAcesso from '@/view/primeiro-acesso/index.vue';
import CadastroVerificacao from '@/view/cadastro-verificacao/index.vue';
import AlterarSenha from '@/view/alterar-senha/index.vue';
import RedefinirSenha from '@/view/redefinir-senha/index.vue';
import ServiceLogin from "../components/security/ServiceLogin";
import ServiceChangePassword from "../components/security/ServiceChangePassword";
import ServiceRedefinePassword from "../components/security/ServiceRedefinePassword";
import ServiceCreatePassword from "../components/security/ServiceCreatePassword";
import CreateUserWithExistingAccount from "../components/security/CreateUserWithExistingAccount";
import Termos from '@/view/termos/index.vue';
import UserNotEnableToAccess from "../components/security/UserNotEnableToAccess";
import UserLocked from "../components/security/UserLocked";
import NotificationPreferences from './../view/notificacoes/preferences'

const checkNotfound = (to, from, next) => {
    // console.log('checkNotfound: ' + to.fullPath)
    var redirectTo = 'notfound'

    // eslint-disable-next-line no-undef
    if (process.env.BASE_URL.includes(to.fullPath) || to.fullPath.includes('index'))
        redirectTo = 'root'

    next({
        name: redirectTo
    })
}

const routes = [
    {
        name: 'login',
        path: '/login',
        components: {
            containerkeepalive: login
        },
        meta: {
            auth: false,
        }
    },
    {
        name: 'notification-preferences',
        path: '/notification/preferences',
        components: {
            containerkeepalive: NotificationPreferences
        },
        meta: {
            auth: false,
        }
    },
    {
        name: 'redefinir-senha',
        path: '/redefinir-senha',
        components: {
            containerkeepalive: RedefinirSenha,
        },
        meta: {
            auth: false,
        },
    },
    {
        name: 'logout',
        path: '/logout',
        redirect: {
            name: 'login'
        },
        meta: {
            auth: false,
        }
    },
    {
        name: 'serviceLogin',
        path: '/serviceLogin',
        components: {
            containerkeepalive: ServiceLogin
        },
        meta: {
            auth: false,
        }
    },
    {
        name: 'serviceRedefinePassword',
        path: '/serviceRedefinePassword',
        components: {
            containerkeepalive: ServiceRedefinePassword
        },
        meta: {
            auth: false,
        }
    },
    {
        name: 'serviceCreatePassword',
        path: '/serviceCreatePassword',
        components: {
            containerkeepalive: ServiceCreatePassword
        },
        meta: {
            auth: false,
        }
    },
    {
        name: 'serviceChangePassword',
        path: '/serviceChangePassword',
        components: {
            containerkeepalive: ServiceChangePassword
        },
        meta: {
            auth: false,
        }
    },
    {
        name: 'createUserWithExistingAccount',
        path: '/createUserWithExistingAccount',
        components: {
            containerkeepalive: CreateUserWithExistingAccount
        },
        meta: {
            auth: false,
        }
    },
    {
        name: 'serviceLoginCallback',
        path: '/serviceLoginCallback',
        beforeEnter(to, from, next) {
            if(to.query.callbackType === 'redirect'){
                parent.window.location = `${$store.state.callbackServiceLogin}?tokenSecurityService=${$store.state.token}?tinnyToken=${$store.state.tinnyToken}`;
            }else{

                let socialType = $store.state.userServiceLogin && $store.state.userServiceLogin.socialType ? $store.state.userServiceLogin.socialType : null;
                let profilePicture = $store.state.userServiceLogin && $store.state.userServiceLogin.profilePicture ? $store.state.userServiceLogin.profilePicture : null;

                top.postMessage({
                    'event': 'callbackServiceLogin',
                    'tokenSecurityService': $store.state.token,
                    'tinnyToken': $store.state.tinnyToken,
                    'email': $store.state.email,
                    'password': $store.state.password,
                    'socialType': socialType,
                    'profilePicture': profilePicture
                }, '*');
            }
        }
    },
    {
        name: 'serviceDefaultReturn',
        path: '/serviceDefaultReturn',
        beforeEnter(to, from, next) {
            if(to.query.callbackType === 'redirect'){
                parent.window.location = `${$store.state.callbackServiceLogin}`;
            }else{
                top.postMessage({'event': 'serviceDefaultReturn'}, '*');
            }
        }
    },
    {
        name: 'userNotEnableToAccess',
        path: '/userNotEnableToAccess',
        components: {
            containerkeepalive: UserNotEnableToAccess
        },
        meta: {
            auth: false,
        }
    },
    {
        name: 'userLocked',
        path: '/userLocked',
        components: {
            containerkeepalive: UserLocked
        },
        meta: {
            auth: false,
        }
    },
    {
        name: 'root',
        path: '/',
        meta: {
            auth: true,
        },
        components: {
            container: portal
        },
        children: [
            {
                name: 'cadastro-verificacao',
                path: 'cadastro-verificacao',
                component: CadastroVerificacao,
                meta: {
                    auth: false,
                },
            },
            {
                name: 'primeiro-acesso',
                path: 'primeiro-acesso',
                component: PrimeiroAcesso,
                meta: {
                    auth: false,
                },
            },
            {
                name: 'termos',
                path: 'termos',
                component: Termos,
                meta: {
                    title: 'Termos',
                    root: true,
                    auth: false,
                },
            },
            {
                name: 'home',
                path: 'home',
                component: home,
                meta: {
                    title: 'Home',
                    root: true,
                    auth: true,
                },
            },
            {
                name: 'alterar-senha',
                path: 'alterar-senha',
                component: AlterarSenha,
                meta: {
                    title: 'Alterar Senha',
                    root: true,
                    auth: true,
                }
            },
            {
                name: 'notfound',
                path: 'notfound',
                component: notfound,
            }
        ]
    },
    {
        path: "*",
        beforeEnter: checkNotfound
    },
]

export default new VueRouter({
    mode: 'history',
    // eslint-disable-next-line no-undef
    base: process.env.BASE_URL,
    routes: routes
})
