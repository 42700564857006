<template>
  <v-container
    fluid
    fill-height
    :style="!!enabledStepMode ? { padding: 0 } : null"
  >
    <v-layout align-center justify-center v-if="!enabledStepMode" xs12 sm6 md4 lg3>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <Header/>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 3">
          <v-card :class="($resize && $mq.above(window.mobileSize) ? 'pa-5 mb-16' : 'mb-16')" :style="'margin-bottom: 40px; --access-account-color: ' + this.accessAccountTitleStyleColor">
            <v-card-text>
              <p class="access-account ">Criar Conta</p>
              <v-text-field name="email" label="E-mail" type="text" placeholder="Digite aqui seu e-mail" v-model="form.email"  :error-messages="emailErroValidation" outlined/>
              <Recaptcha :siteKey="captchaSiteKey" @verify="verify" @error="invalidate" @expired="invalidate" :reload="true"/>
            </v-card-text>
            <v-card-actions>
              <v-btn block color="primary" height="48px" @click="register" :loading="busy" :disabled="canRegister()">Cadastrar</v-btn>
            </v-card-actions>
            <v-card-actions>
              <v-btn block color="secondary" class="black--text" height="48px" @click="cancel()" :disabled="busy" >Cancelar</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
    <v-flex :style="{ ...gradientBackgroundStyle }" v-if="enabledStepMode">
      <v-flex
        class="pa-10 pb-0"
        :style="{ ...stepsBackgroundStyle, height: '100vh', width: '100vw' }"
      >
        <p
          class="small-title-steps-mode mb-md-10"
          :style="'margin-top: 20px; --small-title-steps-color: ' + smallTitleStepStyle.color + '; --small-title-steps-size: ' + smallTitleStepStyle.fontSize"
        >
          Seja bem-vindo(a)!
        </p>

        <p
          class="big-title-steps-mode mb-md-10"
          :style="'margin-top: 5px; --big-title-steps-color: ' + bigTitleStepStyle.color + '; --big-title-steps-font-size: ' + bigTitleStepStyle.fontSize"
        >
          Qual seu e-mail?
        </p>

        <v-text-field
          class="mt-7"
          name="email"
          type="text"
          v-model="form.email"
          :error-messages="emailErroValidation"
          @keydown.space="(event) => event.preventDefault()"
          style="margin-bottom: 15px"
        />

        <Recaptcha :siteKey="captchaSiteKey" @verify="verify" @error="invalidate" @expired="invalidate" :reload="true"/>

        <v-layout :style="$vuetify.breakpoint.width < 351 && { marginTop: '35%' }" align-end>
          <v-row align="end">
            <v-col class="pb-0 email-resize" cols="12">
              <v-btn
                block
                color="primary"
                large
                style="margin-bottom: 10px;"
                class="login-btn-steps-mode"
                @click="register"
                :loading="busy"
                :disabled="canRegister()"
              >
                Próximo
              </v-btn>
            </v-col>
            <v-col class="pt-0" cols="12">
              <v-btn
                @click="$router.go(-1)"
                :color="accessAccountTitleStyleColor"
                large
                block
                text
              >
                Voltar
              </v-btn>
            </v-col>
          </v-row>
        </v-layout>
      </v-flex>
    </v-flex>
    <Footer v-if="!enabledStepMode"/>
  </v-container>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import { mask } from 'vue-the-mask';
import Recaptcha from '@/components/recaptcha/index.vue';
import { existsEmaillLogin, existsEmaillLoginAndAppCode, sendEmailForCreateUserWithExistingAccount } from '@/common/securityserver';
import Header from "../../components/security/Header";
import Footer from "../../components/security/Footer";


export default {
  name: "PrimeiroAcesso",
  components: { Recaptcha, Header, Footer },
  directives: { mask },
  data: () => ({
    invalidCredentials: true,
    busy: false,
    emailErroValidation: [],
    form: {
      email: "",
    },
    createWithSocialAccount: false,
  }),
  watch: {
    'form.email': {
      handler: function(val, oldVal) {
        this.form.email = val.toLowerCase();
        this.form.email = this.form.email.replaceAll(' ', '');
        this.validateLogin(val);
      },
    }
  },
  computed: {
    ...mapState([
      "window", "enabledStepMode", "enabledStepMode", "accessAccountTitleStyleColor",
      "loginCardCustomStyle", "extraTitlesStyle", "bigTitleStepStyle",
      "smallTitleStepStyle", "stepsBackgroundStyle", "gradientBackgroundStyle"
    ]),
    isMobileSize() {
      return this.$resize && this.$mq.below(this.window.mobileSize);
    },
    captchaSiteKey(){
      return process.env.VUE_APP_CAPTCHA_SITE_KEY;
    },
    accessAccountTitleStyleColor(){
      return this.$store.state.accessAccountTitleStyleColor;
    },
  },
  methods: {
    ...mapMutations(['showmsg']),
    verify() {
      this.invalidCredentials = false;
    },
    invalidate() {
      this.invalidCredentials = true;
    },
    validateLogin(login){

      if(!login){
        this.emailErroValidation = 'E-mail é obrigatório';
        return;
      }

      if(!(/.+@.+\..+/.test(login))){
        this.emailErroValidation = 'E-mail inválido';
        return;
      }

      existsEmaillLoginAndAppCode(login).then(res =>{
        this.emailErroValidation = res.data ? ['E-mail não disponível'] : null
      });

      this.emailErroValidation = null;

    },
    canRegister() {
      const formHasData = this.form.email && !this.emailErroValidation;

      return !(formHasData && !this.invalidCredentials);
    },
    async register() {
      this.busy = true;
      existsEmaillLogin(this.form.email).then(res =>{
        if(res.data){
          this.showmsg({
            text: 'Para finalizar o cadastro, acesse seu e-mail e siga as instruções.',
            type: "error",
            timeout: -1,
          });

          let user = {
            login : this.form.email
          }

          sendEmailForCreateUserWithExistingAccount(user);
          this.$router.go(-1);
        }else{
          this.$store.commit('email', this.form.email);
          this.$router.push('/primeiro-acesso');
        }
      });
    },
    cancel(){
      this.$store.commit('userServiceLogin', {});
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
  /* large */
  @media (min-height: 670px) and (min-width: 411px){
    .email-resize {
      margin-top: 250px;
    }
  }

  /* medium */
  @media (min-height: 700px) and (min-width: 350px) and (max-width: 410px){
    .email-resize {
      margin-top: 250px;
    }
  }

  /* small */
  @media (max-height: 699px) and (min-width: 350px) and (max-width: 410px){
    .email-resize {
      margin-top: 150px;
    }
  }
</style>