<template>
  <v-container fluid>
    <v-card :style="'--access-account-color: ' + this.accessAccountTitleStyleColor">
      <v-card-text>
          <p class="access-account">Alterar Senha</p>
          <v-text-field name="password" label="Senha atual" type="password" v-model="form.currentPassword" class="mt-3" outlined/>
          <v-text-field
            name="password"
            label="Nova senha"
            :type="showingPassword ? 'text' : 'password'"
            v-model="form.newPassword"
            :append-icon="`far ${showingPassword ? 'fa-eye-slash' : 'fa-eye'} grey--text`"
            @click:append="showingPassword = !showingPassword"
            outlined
            />
            <v-text-field name="password" label="Reinsira a nova senha" type="password" v-model="form.newPasswordConfirmation" :hint="passwordHint" :error="passwordError" class="mt-3" outlined/>
          <PasswordStrengthMeter :password="form.newPassword" @update="(result) => passwordIsEnough = result"/>
          <Recaptcha :siteKey="captchaSiteKey" @verify="verify" @error="invalidate" @expired="invalidate" :reload="true"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer v-if="$resize && $mq.above(window.mobileSize)"/>
        <v-btn large :block="$resize && $mq.below(window.mobileSize)" color="primary" :loading="busy" :disabled="canUpdate" @click="changePassword()">
          Alterar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
    import {mapMutations, mapState} from "vuex";
import PasswordStrengthMeter from "@/components/password-strength-meter/index.vue";
import Recaptcha from '@/components/recaptcha/index.vue';

export default {
  name: "AlerarSenha",
  components: { PasswordStrengthMeter, Recaptcha },
  data: () => ({
    busy: false,
    invalidCredentials: true,
    showingPassword: false,
    passwordIsEnough: false,
    form: {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
    },
  }),
  computed: {
    ...mapState(["window"]),
    currentPasswordIsValid() {
      return this.form.currentPassword.length;
    },
    passwordIsConfirmed() {
      return this.form.newPassword === this.form.newPasswordConfirmation;
    },
    canUpdate() {
      const formHasData = this.form.currentPassword && this.currentPasswordIsValid && this.form.newPassword && this.form.newPasswordConfirmation;
      const passwordFillsConstraints = this.passwordIsConfirmed;

      return !(formHasData && passwordFillsConstraints && !this.invalidCredentials);
    },
    passwordMatches() {
      return (this.form.newPassword === this.form.newPasswordConfirmation);
    },
    passwordHint() {
      return this.passwordMatches ? "" : "As senhas não coincidem";
    },
    passwordError() {
      return this.passwordMatches ? false : true;
    },
    captchaSiteKey(){
      return process.env.VUE_APP_CAPTCHA_SITE_KEY;
    },
      accessAccountTitleStyleColor(){
          return this.$store.state.accessAccountTitleStyleColor;
      },
  },
  methods: {
    ...mapMutations(['showmsg']),
    changePassword(){
        this.busy= true;

        this.$store.dispatch('callApiGateway', {
            method: 'post',
            url: '/user/updatePassword',
            data:  {
                password: this.form.currentPassword,
                newPassword: this.form.newPassword}
        }).then( response => {
            let messageErro = "Senha alterada com sucesso!"
            this.showmsg({
                text: messageErro,
                type: "success",
            });
            this.$router.push('/serviceDefaultReturn');
        }).catch(error => {
            let messageErro = "Não foi possível alterar a senha!"
            if(error.response.data.message && error.response.data.message  == 'Invalid User or Password')
                messageErro = "Senha atual inválida"
            if(error.response.data.message && error.response.data.message  == 'LDAP Password Policy Exception')
                messageErro = "A nova senha não atende os requisitos da Política de Senha"
            this.showmsg({
                text: messageErro,
                type: "error",
            })}
        ).finally(() => this.busy = false);

    },
    verify() {
      this.invalidCredentials = false;
    },
    invalidate() {
      this.invalidCredentials = true;
    },
  },
  beforeMount() {
    this.$store.commit('callbackServiceLogin', this.$route.query.callback);
    this.$store.commit('userRole', this.$route.query.userRole);
    this.$store.commit('appCode', this.$route.query.appCode);
    this.$store.commit('token', this.$route.query.token);
    this.$store.commit('appVersion', this.$route.query.appVersion);


    this.$store.dispatch('setTheme', this);
    this.$store.commit("backgroundColor", this.$route.query.appbgcolor + " !important");
    this.$store.commit('backgroundImage', 'none !important');
  }
};
</script>
