<template>
  <v-snackbar content-class="font-weight-bold" v-model="msgshow" color="#c9aad1" :timeout="msg.timeout" top>
    <span style="color: #532E88;">{{ msg.data.text }}</span>
    <v-btn text icon :color="msg.data.type" @click="hidemsg()">
      <v-icon color="#532E88">fas fa-times</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState(["msg"]),
    msgshow: {
      get: function() {
        return this.msg.show;
      },
      set: function(value) {
        this.tooglemsg(value);
      }
    }
  },
  methods: {
    ...mapMutations(["tooglemsg", "hidemsg"])
  }
};
</script>
