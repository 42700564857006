<template>
  <v-container class="content" :style="isCompany ? 'margin-bottom: 50px;' : 'margin-bottom: 0px'" fluid>
    <v-row class="px-2">
      <v-col cols="12">
        <v-card :disabled="isLoading" :loading="isLoading && isCompany" :rounded="isCompany ? false : 'xl'" :elevation="isCompany  ? 2 : 0">
          <template slot="progress">
            <v-progress-linear :color="getCheckBoxColorByApp" indeterminate></v-progress-linear>
          </template>
          <v-card-title>
            <v-row>
              <v-col cols-12>
                <p class="my-0 pre-text">Olá, <span class="font-weight-bold">{{this.userName}}</span>.</p>
                <p class="subtitle-1 my-0 pre-text">Você pode configurar abaixo, os canais de comunicação que você não deseja receber notificações.</p>
              </v-col>
              <v-col class="mt-5" cols="12">
                <p class="my-0 pre-text">Notificações - Preferências do Usuário</p>
                <v-checkbox class="my-1 pre-text" :color="getCheckBoxColorByApp" label="Não desejo receber nenhuma notificação deste aplicativo." v-model="isCheckedAllSubItemsApp"
                  @change="onChangeCheckedAllSubItemsApp()"></v-checkbox>
              </v-col>
            </v-row>

          </v-card-title>
          <v-card-text>

            <v-row v-if="isCompany">
              <v-col cols="12" lg="6">
                <v-checkbox class="my-0" :color="getCheckBoxColorByApp" v-model="isCheckedAllSubItemsDrive" @change="onChangeAllSubItemsDrive()">
                  <template v-slot:label>
                    <p class="my-0"><span class="font-weight-bold pre-text">Drive</span> - Não desejo receber nenhuma notificação.</p>
                  </template>
                </v-checkbox>
                <v-checkbox class="ml-5 my-0 pre-text" :color="getCheckBoxColorByApp"  v-for="subItem in subItemsDeliveryChannels" :key="subItem.id" :label="subItem.name"
                            :value="subItem.id" v-model="subItemsSelectionDrive" @change="onChangeSubItemsDrive()"></v-checkbox>
              </v-col>

              <v-col cols="12" lg="6">
                <v-checkbox class="my-0" :color="getCheckBoxColorByApp" v-model="isCheckedAllSubItemsCarrierBilling" @change="onChangeAllSubItemsCarrierBilling()">
                  <template v-slot:label>
                    <p class="my-0"><span class="font-weight-bold pre-text">Faturamento</span> - Não desejo receber nenhuma notificação.</p>
                  </template>
                </v-checkbox>
                <v-checkbox class="ml-5 my-0 pre-text" :color="getCheckBoxColorByApp" v-for="subItem in subItemsDeliveryChannels" :key="subItem.id" :label="subItem.name"
                            :value="subItem.id" v-model="subItemsSelectionCarrierBilling" @change="onChangeSubItemsCarrierBilling()"></v-checkbox>
              </v-col>
            </v-row>

            <v-row v-else>
              <v-col cols="12" lg="6">
                <v-checkbox class="my-0" :color="getCheckBoxColorByApp" v-model="isCheckedAllSubItemsMedicalEvent" @change="onChangeAllSubItemsMedicalEvent()">
                  <template v-slot:label>
                    <p class="my-0"><span class="font-weight-bold pre-text">Consulta/Exame/Vacina</span> - Não desejo receber nenhuma notificação.</p>
                  </template>
                </v-checkbox>
                <v-checkbox class="ml-5 my-0 pre-text" :color="getCheckBoxColorByApp" v-for="subItem in subItemsDeliveryChannels" :key="subItem.id" :label="subItem.name"
                            :value="subItem.id" v-model="subItemsSelectionMedicalEvent" @change="onChangeSubItemsMedicalEvent()"></v-checkbox>
              </v-col>

<!--              <v-col cols="12" lg="6">
                <v-checkbox class="my-0" :color="getCheckBoxColorByApp" v-model="isCheckedAllSubItemsFeed" @change="onChangeAllSubItemsFeed()">
                  <template v-slot:label>
                    <p class="my-0"><span class="font-weight-bold pre-text">Publicações</span> - Não desejo receber nenhuma notificação.</p>
                  </template>
                </v-checkbox>
                <v-checkbox class="ml-5 my-0 pre-text" :color="getCheckBoxColorByApp" v-for="subItem in subItemsDeliveryChannels" :key="subItem.id" :label="subItem.name"
                            :value="subItem.id" v-model="subItemsSelectionFeed" @change="onChangeSubItemsFeed()"></v-checkbox>
              </v-col>-->
            </v-row>

            <v-row v-if="appCode && appCode === 'dr_protegido' || appCode === 'rh_protegido'">
              <v-col cols="12" lg="6">
                <v-checkbox class="my-0" :color="getCheckBoxColorByApp" v-model="isCheckedAllSubItemsCase" @change="onChangeAllSubItemsCase()">
                  <template v-slot:label>
                    <p class="my-0"><span class="font-weight-bold pre-text">Atendimento</span> - Não desejo receber nenhuma notificação.</p>
                  </template>
                </v-checkbox>
                <v-checkbox class="ml-5 my-0 pre-text" :color="getCheckBoxColorByApp" v-for="subItem in subItemsDeliveryChannels" :key="subItem.id" :label="subItem.name"
                            :value="subItem.id" v-model="subItemsSelectionCase" @change="onChangeSubItemsCase()"></v-checkbox>
              </v-col>
            </v-row>

          </v-card-text>
          <v-card-action>
            <v-row class="px-4" justify="end">
              <v-col cols="12" align="end">
                <v-btn :block="showBlockWhen"
                       :loading="isLoading"
                       large :rounded="!isCompany ? 'xl' : null"
                       :class="{'mb-2': showBlockWhen, 'btn-confirm-euprotegido': !isCompany, 'btn-confirm-company': isCompany}"
                       class="mr-2"
                       @click="createUserNotificationPreference()">Confirmar</v-btn>
                <v-btn :block="showBlockWhen"
                       :disabled="isLoading"
                       large light :rounded="!isCompany ? 'xl' : null"
                       :class="{'btn-cancel-euprotegido': !isCompany, 'btn-cancel-company': isCompany}"
                       class="black--text btn-card"
                       @click="back()">Cancelar</v-btn>
              </v-col>
            </v-row>
          </v-card-action>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  getFullToken,
  findUserNotificationPreferenceByUserIdAndAppCode,
  saveUserNotificationPreference,
  updateUserNotificationPreference
} from '@/common/securityserver';
import {mapMutations} from "vuex";

export default {
  name: 'notification-preferences',
  data: () => ({
    token: null,
    tinnyToken: null,
    appCode: null,
    isLoading: false,
    encryptedUserId: null,
    isCheckedAllSubItemsApp: false,
    isCheckedAllSubItemsDrive: false,
    isCheckedAllSubItemsCase: false,
    isCheckedAllSubItemsMedicalEvent: false,
    isCheckedAllSubItemsCarrierBilling: false,
    isCheckedAllSubItemsFeed: false,
    subItemsSelectionDrive: [],
    subItemsSelectionCarrierBilling: [],
    subItemsSelectionCase: [],
    subItemsSelectionMedicalEvent: [],
    subItemsSelectionFeed: [],
    subItemsDeliveryChannels: [
      { id: 'DEFAULT_NOTIFICATION', name: 'Não desejo receber Notificação Padrão.' },
      { id: 'EMAIL_NOTIFICATION', name: 'Não desejo receber Notificação via E-mail.' },
    ],
    userName: null,
    userNotificationPreferences: [],
    userNotificationPreferencesAux: [],
  }),
  computed: {
    showBlockWhen(){
      return this.$vuetify.breakpoint.xs
    },
    isCompany(){
      return this.appCode && (this.appCode  === 'rh_protegido' || this.appCode  === 'dr_protegido');
    },
    getCheckBoxColorByApp(){
      return this.isCompany ? 'primary' : '#292867'
    }
  },
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    loadRouterData(){
      const { data } = this.$route.query;
      this.tinnyToken = this.$route.query.tinnyToken;
      const encryptedTokenParts = data.split('.');

      let decryptedTokenParts = [];
      if(encryptedTokenParts && encryptedTokenParts.length){
        encryptedTokenParts.forEach(parts => {
          decryptedTokenParts.push(atob(parts));
        })
      }

      this.encryptedUserId = decryptedTokenParts[0];
      this.userName = decryptedTokenParts[1];
      this.appCode = decryptedTokenParts[2];
    },
    async loadTokenFull() {
        if(this.tinnyToken) {
          await getFullToken(this.tinnyToken)
            .then((response) => {
              if(response) {
                  this.token = response.token;
              }
            });
        }
    },
    async findByUserIdAndAppCode(){
      await findUserNotificationPreferenceByUserIdAndAppCode(this.token, this.appCode, this.encryptedUserId)
          .then(response => {
            if(response && response.data) {
              this.userNotificationPreferences = response.data;
              this.userNotificationPreferencesAux = response.data;
            }
          })
          .catch(() => {
            this.showmsg({ text: "Ocorreu um erro ao carregar as configurações de notificações."});
          })
    },
    loadFieldsByUserNotificationPreference(){
      if(this.userNotificationPreferences.length){
        this.userNotificationPreferences.forEach(userNotificationPreference => {
          if(userNotificationPreference.featureType === 'ALL') {
            this.isCheckedAllSubItemsApp = true;
            this.onChangeCheckedAllSubItemsApp();
          }else if(userNotificationPreference.featureType === 'DRIVE') {
            this.subItemsSelectionDrive = userNotificationPreference.deliveryChannels;
            this.onChangeSubItemsDrive();
          }else if(userNotificationPreference.featureType === 'CARRIER_BILLING') {
            this.subItemsSelectionCarrierBilling = userNotificationPreference.deliveryChannels;
            this.onChangeSubItemsCarrierBilling();
          }else if(userNotificationPreference.featureType === 'FEED_ITEM') {
            this.subItemsSelectionFeed = userNotificationPreference.deliveryChannels;
            this.onChangeSubItemsFeed();
          }else if(userNotificationPreference.featureType === 'MEDICAL_EVENT') {
            this.subItemsSelectionMedicalEvent = userNotificationPreference.deliveryChannels;
            this.onChangeSubItemsMedicalEvent();
          }else if(userNotificationPreference.featureType === 'CASE') {
            this.subItemsSelectionCase = userNotificationPreference.deliveryChannels;
            this.onChangeSubItemsCase();
          }
        })
      }
    },
    async createUserNotificationPreference(){
      this.isLoading = true;

      let featureType = null;
      if(this.appCode && (this.appCode === 'rh_protegido' || this.appCode === 'dr_protegido')){
        featureType = (this.subItemsSelectionDrive.length == 3 && this.subItemsSelectionCarrierBilling.length == 3
            && this.subItemsSelectionCase.length == 3) ? 'ALL' : null;
      } else {
        featureType = (this.subItemsSelectionMedicalEvent.length == 3 && this.subItemsSelectionFeed.length == 3
            && this.subItemsSelectionCase.length == 3) ? 'ALL' : null;
      }

      let userNotificationPreferences = [];
      if(featureType === 'ALL'){
        const userNotificationPreference = {
          appCode: this.appCode,
          deliveryChannels: this.isCompany ? this.subItemsSelectionDrive.sort() : this.subItemsSelectionMedicalEvent.sort(),
          encryptedUserId: this.encryptedUserId,
          featureType: featureType,
        }

        userNotificationPreferences.push(userNotificationPreference);
      } else {
        userNotificationPreferences = this.configureUserNotificationPreferencesByAppCode();
      }

      if(userNotificationPreferences.length && this.userNotificationPreferences.length === 0){
        await this.newUserNotificationPreference(userNotificationPreferences);
      } else if(userNotificationPreferences.length && this.userNotificationPreferences.length){
        await this.changeUserNotificationPreference(userNotificationPreferences);
      } else if(this.userNotificationPreferencesAux.length > 0 && userNotificationPreferences.length === 0 && this.userNotificationPreferences.length === 0){
        await this.changeUserNotificationPreference([]);
      } else {
        this.showmsg({ text: "As configurações padrões foram mantidas.", type: this.isCompany ? 'success' : '#4CAF50'});
      }
      this.isLoading = false;

    },
    configureUserNotificationPreferencesByAppCode(){
      let userNotificationPreference = null;
      let userNotificationPreferences = [];
      if(this.appCode && (this.appCode === 'rh_protegido' || this.appCode === 'dr_protegido')){

        if(this.subItemsSelectionDrive.length > 0) {
          userNotificationPreference = {
            appCode: this.appCode,
            deliveryChannels: this.subItemsSelectionDrive.sort(),
            encryptedUserId: this.encryptedUserId,
            featureType: 'DRIVE',
          }
          userNotificationPreferences.push(userNotificationPreference);
        }

        if(this.subItemsSelectionCarrierBilling.length > 0) {
          userNotificationPreference = {
            appCode: this.appCode,
            deliveryChannels: this.subItemsSelectionCarrierBilling.sort(),
            encryptedUserId: this.encryptedUserId,
            featureType: 'CARRIER_BILLING',
          }
          userNotificationPreferences.push(userNotificationPreference);
        }

      } else {

        if(this.subItemsSelectionFeed.length > 0) {
          userNotificationPreference = {
            appCode: this.appCode,
            deliveryChannels: this.subItemsSelectionFeed.sort(),
            encryptedUserId: this.encryptedUserId,
            featureType: 'FEED_ITEM',
          }
          userNotificationPreferences.push(userNotificationPreference);
        }

        if(this.subItemsSelectionMedicalEvent.length > 0) {
          userNotificationPreference = {
            appCode: this.appCode,
            deliveryChannels: this.subItemsSelectionMedicalEvent.sort(),
            encryptedUserId: this.encryptedUserId,
            featureType: 'MEDICAL_EVENT',
          }
          userNotificationPreferences.push(userNotificationPreference);
        }

      }

      if(this.subItemsSelectionCase.length > 0) {
        userNotificationPreference = {
          appCode: this.appCode,
          deliveryChannels: this.subItemsSelectionCase.sort(),
          encryptedUserId: this.encryptedUserId,
          featureType: 'CASE',
        }
        userNotificationPreferences.push(userNotificationPreference);
      }

      return userNotificationPreferences;
    },
    async newUserNotificationPreference(userNotificationPreferences){

      await saveUserNotificationPreference(this.token, userNotificationPreferences)
          .then(response => {
            if(response){
              this.showmsg({ text: "As configurações foram salvas com sucesso.", type: this.isCompany ? 'success' : '#4CAF50'});
            }
          })
          .catch(() => {
            this.showmsg({ text: "Ocorreu um erro ao tentar salvar as configurações."});
          })
    },
    async changeUserNotificationPreference(userNotificationPreferences){

      await updateUserNotificationPreference(this.token, userNotificationPreferences, this.appCode, this.encryptedUserId)
          .then(response => {
            if(response){
              this.showmsg({ text: "As configurações foram atualizadas com sucesso.", type: this.isCompany ? 'success' : '#4CAF50'});
            }
          })
          .catch(() => {
            this.showmsg({ text: "Ocorreu um erro ao tentar atualizar as configurações."});
          })
    },
    onChangeCheckedAllSubItemsApp(){
      if(!this.isCheckedAllSubItemsApp) {
        this.userNotificationPreferences = [];
      }

      if(this.appCode && (this.appCode === 'rh_protegido' || this.appCode === 'dr_protegido')){
        this.isCheckedAllSubItemsDrive = this.isCheckedAllSubItemsApp;
        this.isCheckedAllSubItemsCase = this.isCheckedAllSubItemsApp;
        this.isCheckedAllSubItemsCarrierBilling = this.isCheckedAllSubItemsApp;

        this.onChangeAllSubItemsDrive();
        this.onChangeAllSubItemsCase();
        this.onChangeAllSubItemsCarrierBilling();

      }else {
        this.isCheckedAllSubItemsMedicalEvent = this.isCheckedAllSubItemsApp;
        this.isCheckedAllSubItemsCase = this.isCheckedAllSubItemsApp;
        this.isCheckedAllSubItemsFeed = this.isCheckedAllSubItemsApp;

        this.onChangeAllSubItemsMedicalEvent();
        this.onChangeAllSubItemsCase();
        this.onChangeAllSubItemsFeed();
      }
    },
    onChangeAllSubItemsDrive(){
      this.setStatusSubItemsDrive(this.isCheckedAllSubItemsDrive);
    },
    onChangeAllSubItemsCase(){
      this.setStatusSubItemsCase(this.isCheckedAllSubItemsCase);
    },
    onChangeAllSubItemsCarrierBilling(){
      this.setStatusSubItemsCarrierBilling(this.isCheckedAllSubItemsCarrierBilling);
    },
    onChangeAllSubItemsMedicalEvent(){
      this.setStatusSubItemsMedicalEvent(this.isCheckedAllSubItemsMedicalEvent);
    },
    onChangeAllSubItemsFeed(){
      this.setStatusSubItemsFeed(this.isCheckedAllSubItemsFeed);
    },
    onChangeSubItemsApp(){
      if(this.appCode && (this.appCode === 'rh_protegido' || this.appCode === 'dr_protegido')) {
        this.isCheckedAllSubItemsApp = (this.isCheckedAllSubItemsDrive && this.isCheckedAllSubItemsCarrierBilling
            && this.isCheckedAllSubItemsCase)
      } else {
        this.isCheckedAllSubItemsApp = (this.isCheckedAllSubItemsMedicalEvent && this.isCheckedAllSubItemsFeed
            && this.isCheckedAllSubItemsCase)
      }
    },
    onChangeSubItemsDrive(){
      this.isCheckedAllSubItemsDrive = (this.subItemsSelectionDrive.length >= 2);
      this.onChangeSubItemsApp();
    },
    onChangeSubItemsCase(){
      this.isCheckedAllSubItemsCase = (this.subItemsSelectionCase.length >= 2);
      this.onChangeSubItemsApp();
    },
    onChangeSubItemsCarrierBilling(){
      this.isCheckedAllSubItemsCarrierBilling = (this.subItemsSelectionCarrierBilling.length >= 2);
      this.onChangeSubItemsApp();
    },
    onChangeSubItemsMedicalEvent(){
      this.isCheckedAllSubItemsMedicalEvent = (this.subItemsSelectionMedicalEvent.length == 3);
      this.onChangeSubItemsApp();
    },
    onChangeSubItemsFeed(){
      this.isCheckedAllSubItemsFeed = (this.subItemsSelectionFeed.length == 3);
      this.onChangeSubItemsApp();
    },
    setStatusSubItemsDrive(newValue){
      this.subItemsSelectionDrive = [];
      if(newValue) {
        this.subItemsDeliveryChannels.forEach(subItem => {
          this.subItemsSelectionDrive.push(subItem.id);
        })
      }
      this.onChangeSubItemsApp();
    },
    setStatusSubItemsCase(newValue){
      this.subItemsSelectionCase = [];
      if(newValue) {
        this.subItemsDeliveryChannels.forEach(subItem => {
          this.subItemsSelectionCase.push(subItem.id);
        })
      }
      this.onChangeSubItemsApp();
    },
    setStatusSubItemsCarrierBilling(newValue){
      this.subItemsSelectionCarrierBilling = [];
      if(newValue) {
        this.subItemsDeliveryChannels.forEach(subItem => {
          this.subItemsSelectionCarrierBilling.push(subItem.id);
        })
      }
      this.onChangeSubItemsApp();
    },
    setStatusSubItemsMedicalEvent(newValue){
      this.subItemsSelectionMedicalEvent = [];
      if(newValue) {
        this.subItemsDeliveryChannels.forEach(subItem => {
          this.subItemsSelectionMedicalEvent.push(subItem.id);
        })
      }
      this.onChangeSubItemsApp();
    },
    setStatusSubItemsFeed(newValue){
      this.subItemsSelectionFeed = [];
      if(newValue) {
        this.subItemsDeliveryChannels.forEach(subItem => {
          this.subItemsSelectionFeed.push(subItem.id);
        })
      }
      this.onChangeSubItemsApp();
    },
    back(){
      this.isLoading = true;
      this.$router.back();
    }
  },
  async created() {
    this.isLoading = true;
    this.loadRouterData();
    await this.loadTokenFull();
    await this.findByUserIdAndAppCode();
    this.loadFieldsByUserNotificationPreference();
    this.isLoading = false;
  }
};

</script>

<style scoped>
.content {
  background-color: white;
  height: 100%;
}

.btn-confirm-euprotegido {
  background-color: #292867 !important;
  border-radius: 20px !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 27px !important;
  height: 56px !important;
  text-transform: capitalize !important;
  color: white;
}

.btn-cancel-euprotegido {
  border-radius: 20px !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 27px !important;
  height: 56px !important;
  text-transform: capitalize !important;
  color: #292867 !important;
}

.btn-confirm-company {
  width: 150px;
  background-color: #e53a5d !important;
  color: white;
}

.btn-cancel-company {
  width: 150px;
}

.pre-text{
  white-space: pre-wrap;
  word-break: keep-all;
}
</style>
