/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import Vue from 'vue'

import { MediaQueries } from 'vue-media-queries';
import customvuetify from './plugins/vuetify'
import './plugins/fontawesome'
import 'babel-polyfill';

import store from './store/store.js'
import router from './router/router.js'
import './registerServiceWorker.js'
import util from './common/util.js'

import App from './App'


import { mapMutations } from 'vuex';

const mediaQueries = new MediaQueries();

Vue.use(mediaQueries);

import GoogleAuth from './components/security/google_oAuth'
import $router from "./router/router";

const gauthOption = {
  clientId: '640133584597-puuemj79qha5n3on5e9f8ia08s6c0l87.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GoogleAuth, gauthOption)

Vue.config.productionTip = false

Vue.component('v-style', {
  render: function (createElement) {
    return createElement('style', this.$slots.default)
  }
});


var vm = new Vue({
  store,
  router,
  render: h => h(App),
  vuetify: customvuetify,
  mediaQueries,
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize();

    window.addEventListener('scroll', this.handleScroll)
    this.handleScroll();

    window.addEventListener('beforeinstallprompt', (event) => {
      try {
        event.prompt();
        //eslint-disable-next-line
      } catch (err) {}
    });

    window.addEventListener('message', async (event) => {
      if(event && event.data && event.data === 'redirectToTerms') {
        await $router.push('/termos');
      } else if(event && event.data && event.data === 'accessViolation') {
        await $router.push('/userNotEnableToAccess');
      }
    });
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapMutations(['handleResize', 'handleScroll'])
  }
})

Vue.use(util, {
  vm: vm
})

vm.$mount('#app')
