<template>
  <v-container>
    <v-overlay :value="loading" :opacity="0">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
    <Header :class="($resize && $mq.above(window.mobileSize) ? 'mt-16' : 'mt-0')"/>
    <v-card
      :class="($resize && $mq.above(window.mobileSize) ? 'pa-5 mt-14' : 'pb-2 mt-8')"
      v-if="passwordRedefined"
    >
      <v-row align-content="center" align="center" justify="center">
        <v-col cols="12">
          <p class="text-center mt-5" style="font-size: 22px">Sua senha foi alterada com sucesso!</p>
        </v-col>

        <v-col cols="12">
          <p class="text-center">Para acessar vá até seu aplicativo instalado.</p>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      v-if="!passwordRedefined"
      :class="($resize && $mq.above(window.mobileSize) ? 'pa-5 mt-14' : 'pb-2 mt-10')"
      v-show="validToken"
    >
      <v-card-title>Redefinir Senha</v-card-title>
      <v-card-text>
        <v-text-field
          name="password"
          label="Nova senha"
          :type="showingPassword ? 'text' : 'password'"
          v-model="form.newPassword"
          :append-icon="`far ${showingPassword ? 'fa-eye-slash' : 'fa-eye'} grey--text`"
          @click:append="showingPassword = !showingPassword"
        />
        <v-text-field
            name="password"
            label="Reinsira a nova senha"
            :type="showingConfirmPassword ? 'text' : 'password'"
            v-model="form.newPasswordConfirmation"
            :append-icon="`far ${showingConfirmPassword ? 'fa-eye-slash' : 'fa-eye'} grey--text`"
            @click:append="showingConfirmPassword = !showingConfirmPassword"
            :hint="passwordHint"
            :error="passwordError"
            class="mt-3"/>
        <PasswordStrengthMeter :password="form.newPassword" @update="(result) => passwordIsEnough = result"/>
        <Recaptcha :siteKey="captchaSiteKey" @verify="verify" @error="invalidate" @expired="invalidate" :reload="true"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer v-if="$resize && $mq.above(window.mobileSize)"/>
        <v-btn :block="$resize && $mq.below(window.mobileSize)" color="primary" :loading="busy" :disabled="canUpdate" @click="redefinePassword()">
          Alterar
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="mt-7" v-show="validToken != null && !validToken">
      <v-card-title>Seu token expirou</v-card-title>
      <v-card-text class="pb-5">
        <p>Este token está expirado. Por segurança, o token é válido por 3 dias. <v-btn style="padding: 0" text small color="primary" @click="resendToken()">Clique aqui</v-btn> para enviar um novo token ao seu e-mail utilizado para envio deste link.</p>
      </v-card-text>
    </v-card>
    <v-card class="mt-7" v-show="tokenNotExists">
      <v-card-title>Token inválido</v-card-title>
      <v-card-text class="pb-5">
        <p>O seu token é inválido ou já foi utilizado. Caso tenha esquecido a senha,  <v-btn to="/redefinir-senha" style="padding: 0" text small color="primary">solicite aqui</v-btn> .</p>
      </v-card-text>
    </v-card>
    <template v-slot:loading>Carregando</template>
  </v-container>
</template>

<script>
  import {mapMutations, mapState} from "vuex";
  import PasswordStrengthMeter from "../../components/password-strength-meter/index.vue";
  import Recaptcha from '@/components/recaptcha/index.vue';
  import {resendEmailToken, validateEmailToken} from '@/common/securityserver';
  import Header from "./Header";

  export default {
    name: "AlerarSenha",
    components: { PasswordStrengthMeter, Recaptcha, Header },
    data: () => ({
      busy: false,
      loading: false,
      validToken: null,
      tokenNotExists: false,
      invalidCredentials: true,
      showingPassword: false,
      showingConfirmPassword: false,
      passwordIsEnough: false,
      form: {
        newPassword: "",
        newPasswordConfirmation: "",
      },
      passwordRedefined: false,
    }),
    computed: {
      ...mapState([
          "window",
      ]),
      passwordIsConfirmed() {
        return this.form.newPassword === this.form.newPasswordConfirmation;
      },
      canUpdate() {
        const formHasData = this.form.newPassword && this.form.newPasswordConfirmation && this.passwordIsEnough;
        const passwordFillsConstraints = this.passwordIsConfirmed;

        return !(formHasData && passwordFillsConstraints && !this.invalidCredentials);
      },
      passwordMatches() {
        return (this.form.newPassword === this.form.newPasswordConfirmation);
      },
      passwordHint() {
        return this.passwordMatches ? "" : "As senhas não coincidem";
      },
      passwordError() {
        return this.passwordMatches ? false : true;
      },
      captchaSiteKey(){
        return process.env.VUE_APP_CAPTCHA_SITE_KEY;
      }
    },
    methods: {
      ...mapMutations(['showmsg']),

      redefinePassword() {
        this.busy= true;

        this.$store.dispatch('callApiGatewayWithoutToken', {
          method: 'post',
          url: '/user/redefinePassword',
          data: {
            emailToken: this.$route.query.token,
            newPassword: this.form.newPassword
          }
        }).then( response => {
          this.passwordRedefined = true;
        }).catch(error => {
            let messageErro = "Não foi possível alterar a senha!"
            if(error.response.data.message && error.response.data.message  == 'Token has already expired')
              messageErro = "Token expirado. Solicite um novo token para definir a senha"
            if(error.response.data.message && error.response.data.message  == 'LDAP Password Policy Exception')
              messageErro = "A nova senha não atende os requisitos da Política de Senha"
            this.showmsg({
              text: messageErro,
              type: "error",
            })}
        ).finally(() => this.busy = false);
      },
      resendToken() {
        this.loading = true;
        resendEmailToken().then(response => {
          if(response.error){
            this.$store.commit('showmsg', { text: 'Não foi possível definir a senha!', type: "error" });
          }else{
            const msg = `Será enviado um e-mail para você com as instruções necessárias para definir sua senha.`;
            this.$store.commit('showmsg', { text: msg, type: "success" });
            this.$router.push('/serviceLogin');
          }
        }).catch(error => {
          this.showmsg({
            text: 'Não foi possível definir a senha!',
            type: "error",
          })}
        ).finally(() => this.loading = false);
      },
      verify() {
        this.invalidCredentials = false;
      },
      invalidate() {
        this.invalidCredentials = true;
      },
    },

    async beforeMount() {
      this.$store.commit('callbackServiceLogin', this.$route.query.callback);
      this.$store.commit('userRole', this.$route.query.userRole);
      this.$store.commit('appCode', this.$route.query.appCode);
      this.$store.commit('token', this.$route.query.token);

      this.$store.dispatch('setTheme', this);

      await validateEmailToken(this.$route.query.token)
          .then(res => { this.validToken = res })
          .catch(error => { this.tokenNotExists = true })
    }
  };
</script>
