<template>
  <v-navigation-drawer v-model="menur" app hide-overlay height temporary right>
    <v-list class="pa-1">
      <v-list-item>
        <v-list-item-avatar>
          <img :src="user.img" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{user.nome}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{user.email}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list class="pt-0" dense>
      <v-divider></v-divider>

      <v-list-item v-for="item in items" :key="item.title" @click="link(item.link)">
        <v-list-item-action>
          <v-icon>fas fa-{{ item.icon }}</v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/alterar-senha">
        <v-list-item-action>
          <v-icon>fas fa-key</v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>Alterar senha</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider/>

      <v-list-item @click="logout()">
        <v-list-item-action>
          <v-icon>fas fa-sign-out-alt</v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>Sair</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations(["updateMenuState"]),
    link(name) {
      this.$router.push({ name: name });
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
  },
  computed: {
    ...mapState(["menu"]),
    ...mapGetters(['user']),
    menur: {
      get: function() {
        return this.menu.right;
      },
      set: function(value) {
        this.updateMenuState({ right: value });
      }
    }
  },
  data: () => ({
    items: []
  })
};
</script>
