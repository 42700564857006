import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/src/styles/main.sass';

let thinkcare_theme = {
  primary: '#e53a5d',
  secondary: '#292867',
  accent: '#ea4965',
  error: '#E53A5D',
  info: '#375176',
  success: '#904C92',
  warning: '#1d1938',
  contrastSoft: "#4d4090",
  contrast: "#f2ece6",
  contrastHard: "#292867",
  background: '#292867',
  facebook: '#4267B2',
  google: '#FFFFFF',
}

let theme = {
  themes: {
    light: thinkcare_theme,
    dark: thinkcare_theme
  },
};

Vue.use(Vuetify);

export default new Vuetify({
  theme,
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
});
