import Vue from 'vue'

import Vuex from 'vuex'
Vue.use(Vuex)

import merge from 'deepmerge';

import app from './app.js';
import msg from './msg.js';
import breadcrumb from './breadcrumb.js';
import menu from './menu.js';
import window from './window.js';
import session from './session.js';
import users from './users.js';
import serviceLogin from "./serviceLogin";

const mainStore = {
  strict: true,
}

const finalStore = merge.all([
  mainStore,
  app,
  msg,
  breadcrumb,
  menu,
  window,
  session,
  users,
  serviceLogin,
]);


export default new Vuex.Store(finalStore);
