<template>
  <v-container>
    <v-card class="mt-5">
      <v-card-title>Alterar Senha</v-card-title>
      <v-card-text>
          <v-text-field name="password" label="Senha atual" type="password" v-model="form.currentPassword" class="mt-3" outlined/>
          <v-text-field
            name="password"
            label="Nova senha"
            :type="showingPassword ? 'text' : 'password'"
            v-model="form.newPassword"
            :append-icon="`far ${showingPassword ? 'fa-eye-slash' : 'fa-eye'} grey--text`"
            @click:append="showingPassword = !showingPassword"
            outlined
            />
            <v-text-field name="password" label="Reinsira a nova senha" type="password" v-model="form.newPasswordConfirmation" :hint="passwordHint" :error="passwordError" class="mt-3" outlined/>
          <PasswordStrengthMeter :password="form.newPassword" @update="(result) => passwordIsEnough = result"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer v-if="$resize && $mq.above(window.mobileSize)"/>
        <v-btn :block="$resize && $mq.below(window.mobileSize)" color="primary" type="submit" :loading="busy" :disabled="canUpdate">
          Alterar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import PasswordStrengthMeter from "@/components/password-strength-meter/index.vue";

export default {
  name: "AlerarSenha",
  components: { PasswordStrengthMeter },
  data: () => ({
    busy: false,
    showingPassword: false,
    passwordIsEnough: false,
    form: {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
    },
  }),
  computed: {
    ...mapState(["window"]),
    currentPasswordIsValid() {
      return this.form.currentPassword.length;
    },
    passwordIsConfirmed() {
      return this.form.newPassword === this.form.newPasswordConfirmation;
    },
    canUpdate() {
      const formHasData = this.form.currentPassword && this.currentPasswordIsValid && this.form.newPassword && this.form.newPasswordConfirmation;
      const passwordFillsConstraints = this.passwordIsConfirmed && this.passwordIsEnough;

      return !(formHasData && passwordFillsConstraints);
    },
    passwordMatches() {
      return (this.form.newPassword === this.form.newPasswordConfirmation);
    },
    passwordHint() {
      return this.passwordMatches ? "" : "As senhas não coincidem";
    },
    passwordError() {
      return this.passwordMatches ? false : true;
    },
  },
};
</script>
