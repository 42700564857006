<template>
    <v-col v-if="appLogo" cols="12" :class="'text-center pa-0' + ($resize && $mq.above(window.mobileSize) ? 'mb-12' : '') ">
        <img :src="appLogo.src" :style="($resize && $mq.above(window.mobileSize) ? `width:${appLogo.width}` : `width:${appLogo.mobileWidth}`)" />
        <p class="appTitle">{{ appTitle }}</p>
    </v-col>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "logo",
    computed:{
      ...mapState(["window", 'appLogo', 'appTitle']),
    }
}
</script>
