<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <p v-html="template.body"></p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" class="black--text" @click="back()">Voltar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import {getUserAccessNotValidTemplate} from '@/common/securityserver';
  import {mapMutations} from "vuex";

  export default {
    name: 'UserNotEnableToAccess',
    data: () => ({
      template : {}
    }),
    async beforeMount() {
      await getUserAccessNotValidTemplate().then(response => { this.template = response;})
      this.loading(false);
    },
    methods: {
      ...mapMutations(["showmsg", "loading"]),
      back(){
        this.$router.push('/serviceDefaultReturn')
      }
    },
  };
</script>
