export default {
  state: {
    menu: {
      left: false,
      leftmini: false,
      right: false,
    },
  },
  mutations: {
    updateMenuState(state, menu) {
      state.menu = Object.assign(state.menu, menu);
    },
    toggleMenul(state) {
      if (this.getters.isMobileSize) {
        state.menu.left = !state.menu.left;
      } else {
        state.menu.leftmini = !state.menu.leftmini;
      }
    },
    toggleMenur(state) {
      state.menu.right = !state.menu.right;
    },
  },
};
