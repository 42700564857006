<template>
  <v-btn
    color="facebook"
    class="white--text"
    block
    @click="login()"
    large
  >
    <v-icon left>fab fa-facebook-square</v-icon>Entrar com o Facebook
  </v-btn>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data: () => ({
  }),
  beforeMount() {
    this.initialize();
  },
  methods: {
    ...mapMutations(["showmsg", "toogleDialogLoading", "loading"]),
    async initialize() {
      await this.loadFacebookSDK(document, "script", "facebook-jssdk")
      await this.initFacebook();
    },
    async initFacebook() {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId: "1949668155135986", //You will need to change this
          cookie: true, // This is important, it's not enabled by default
          version: "v8.0"
        });
      };
    },
    async loadFacebookSDK(d, s, id) {
      var js,
              fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/pt_BR/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
    login() {
      this.loading(true);
      var thiz = this;
      //eslint-disable-next-line
      FB.login(function(response) {
        if (!(typeof this.facebookOnSuccess == "function"))
          window.facebookOnSuccess = thiz.facebookOnSuccess;

        thiz.facebookOnSuccess(response);
      }, {scope: 'public_profile,email'});
    },
    facebookOnSuccess(response) {
      //console.log(response);
      var thiz = this;
      if (response.status === "connected") {
        this.toogleDialogLoading(true);
        // eslint-disable-next-line
        var socialAccessToken =   FB.getAuthResponse()['accessToken'];
        //eslint-disable-next-line
        FB.api("/me", { fields: "name,email,picture.width(480)" }, function(fbdata) {
          //console.log(fbdata);
          let user = {
            name: fbdata.name,
            login: fbdata.email,
            email: fbdata.email,
            socialId: fbdata.id,
            socialType: 'facebook',
            authenticationProviderCode: 'facebook',
            socialAccessToken: socialAccessToken,
            profilePicture: fbdata.picture.data.url,
          };

          thiz.$store.commit('userServiceLogin', user);

          thiz.$store.dispatch("serviceLogin", user)
                  .finally(() => (thiz.loading(false)));

        });
      } else {
        this.showmsg({ text: "Acesso não autorizado", type: "error" });
        this.loading(false);
      }
    }
  }
};
</script>
