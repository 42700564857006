<template>
  <div>
    <v-dialog v-model="informationsOpen" max-width="480">
      <v-card>
        <v-card-title primary-title dark class="primary white--text">
          Política de Senha
        </v-card-title>

        <v-card-text class="py-4">
          {{passwordPolicyHelpText}}
        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="informationsOpen = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <p class="mb-5 mt-n3">
      Política de Senha:
      <v-btn icon @click="informationsOpen = true">
        <v-icon small color="grey">far fa-question-circle</v-icon>
      </v-btn>
    </p>
<!--    <v-row class="py-0">-->
<!--      <v-col cols="4">-->
<!--        <v-card class="pa-2 text-center" :color="`${passwordStrength < 3 ? 'red white&#45;&#45;text' : 'grey lighten-5'}`" outlined tile>Fraca</v-card>-->
<!--      </v-col>-->
<!--      <v-col cols="4">-->
<!--        <v-card class="pa-2 text-center" :color="`${passwordStrength >= 3 && passwordStrength <= 4 ? 'orange white&#45;&#45;text' : 'grey lighten-5'}`" outlined tile>Média</v-card>-->
<!--      </v-col>-->
<!--      <v-col cols="4">-->
<!--        <v-card class="pa-2 text-center" :color="`${passwordStrength >= 5 ? 'green white&#45;&#45;text' : 'grey lighten-5'}`" outlined tile>Forte</v-card>-->
<!--      </v-col>-->
<!--    </v-row>-->
  </div>
</template>

<script>

export default {
  name: 'PasswordStrengthMeter',
  props: {
    password: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    strengthRegex: /^/,
    informationsOpen: false,
  }),
  computed: {
    hasCorrectSize() {
      return this.password && this.password.length >= 8;
    },
    hasLowerCaseLetter() {
      return /[a-z]/.test(this.password);
    },
    hasUpperCaseLetter() {
      return /[A-Z]/.test(this.password);
    },
    hasNumber() {
      return /[0-9]/.test(this.password);
    },
    hasEspecialCharacter() {
      return /[!@#$%^&*]/.test(this.password);
    },
    passwordStrength() {
      let strength = 0;

      if (this.hasCorrectSize) {
        strength++;
      }
      if (this.hasLowerCaseLetter) {
        strength++;
      }
      if (this.hasUpperCaseLetter) {
        strength++;
      }
      if (this.hasNumber) {
        strength++;
      }
      if (this.hasEspecialCharacter) {
        strength++;
      }

      return strength;
    },
    passwordPolicyHelpText(){
      return `${process.env.VUE_APP_PASSWORD_POLICY}`;
    }
  },
  watch: {
    passwordStrength(newValue) {
      this.$emit('update', newValue === 5 ? true : false);
    },
  },
};

</script>
