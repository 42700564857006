import Vue from 'vue'

const buildBreadCrumbItem = (ritem) => {
  // console.log(ritem)
  return {
    name: ritem.name,
    text: ritem.meta.title,
    params: ritem.params,
    disabled: true,
  }
}

export default {
  state: {
    navigation: [],
  },
  mutations: {
    buildBreadCrumb(state, $vue_instance) {
      const route = $vue_instance.$route
      // console.log(route)
      const routes = $vue_instance.$router.options.routes
      // console.log(routes)

      //zerar se for um item de menu
      if (route.meta.root) {
        var resetBC = true
        // eslint-disable-next-line no-unused-vars
        for (const [nindex, nitem] of state.navigation.entries()) {
          if (nitem.name === route.name) {
            resetBC = false
            break
          }
        }
        if (resetBC)
          state.navigation.splice(0)
      }

      //remover o ultimo se for mesmo a ser adicionado
      const nlength = state.navigation.length - 1
      if (nlength >= 0 && state.navigation[nlength].name === route.name)
        state.navigation.pop()

      //desabilitar todos exeto o atual se ja estiver na pilha
      for (const [nindex, nitem] of state.navigation.entries()) {
        if (nitem.name === route.name) {
          for (const [nindex2, nitem2] of state.navigation.entries()) {
            Vue.set(state.navigation, nindex2, Object.assign({}, nitem2, {
              disabled: false
            }))
          }

          Vue.set(state.navigation, nindex, Object.assign({}, nitem, {
            disabled: true
          }))

          return
        }
      }

      //inserir pilha de itens de acordo com a hierarquia
      if (state.navigation.length === 0) {
        if (route.meta.bch && route.meta.bch.length > 0) {
          // eslint-disable-next-line no-unused-vars
          for (const [bchindex, bchitem] of route.meta.bch.entries()) {
            // eslint-disable-next-line no-unused-vars
            for (const [rindex, ritem] of routes.entries()) {
              if (ritem.name == bchitem) {
                state.navigation.push(buildBreadCrumbItem(ritem))
                break
              }
              if (ritem.children && ritem.children.length > 0) {
                // eslint-disable-next-line no-unused-vars
                for (const [rcindex, rcitem] of ritem.children.entries()) {
                  if (rcitem.name == bchitem) {
                    state.navigation.push(buildBreadCrumbItem(rcitem))
                    break
                  }
                }
              }
            }
          }
        }
      }

      state.navigation.push(buildBreadCrumbItem(route))

      //habilitar todos exceto o ultimo
      for (const [nindex, nitem] of state.navigation.entries()) {
        if (nindex === (state.navigation.length - 1))
          continue

        Vue.set(state.navigation, nindex, Object.assign({}, nitem, {
          disabled: false
        }))
      }
    },
  },
}