<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            {{template.description}}
          </v-card-title>
          <v-card-text>
            <p v-html="template.body"></p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" class="black--text" @click="back()">Voltar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import {getUserLocked} from '@/common/securityserver';

  export default {
    name: 'UserLocked',
    data: () => ({
      template : {}
    }),
    beforeMount() {
      getUserLocked().then(response => { this.template = response;})
    },
    methods: {
      back(){
        this.$router.push('/serviceDefaultReturn')
      }
    },
  };
</script>
