<template>
  <v-container
      :style="!!enabledStepMode ? { padding: 0 } : null"
      fluid
  >
    <v-row>
      <logo v-if="!enabledStepMode"/>
      <v-col :style="!!enabledStepMode ? { paddingTop: 0, paddingBottom: 0 } : null" cols="12">
        <v-card>
          <v-col className="pt-7 pb-0" cols="12">
            <h1 class="px-4 pl-0" className="principal-color">Termo de Uso</h1>
          </v-col>
          <v-card-title>
            {{termo.description}}
          </v-card-title>
          <v-card-text>
            <p v-html="termo.body"></p>
            <v-row>
              <v-checkbox
                  v-for="accept in accept.checks"
                  :key="accept.id"
                  :label="accept.label"
                  v-model="accept.checked"
                  @change="validateAcceptChecks"
                  :class="accept.mandatory ? 'font-weight-bold mx-2' : 'mx-2'"
              />
            </v-row>
          </v-card-text>
          <v-card-actions v-if="!enabledStepMode">
            <v-spacer></v-spacer>
            <v-btn color="secondary" class="black--text" @click="continueTo(false)" :disabled="busy">
              Agora não
            </v-btn>
            <v-btn color="primary" @click="continueTo(true)" :disabled="!validationChecksOK" :loading="busy">
              Eu aceito
            </v-btn>
          </v-card-actions>

          <v-flex v-if="enabledStepMode">
            <v-card-actions style="margin-top: 20px">
              <v-btn
                  block
                  color="primary"
                  large
                  style="margin-bottom: 5px;"
                  class="login-btn-steps-mode"
                  @click="continueTo(true)"
                  :loading="busy"
                  :disabled="!validationChecksOK"
              >
                Eu aceito
              </v-btn>
            </v-card-actions>

            <v-card-actions>
              <v-btn
                  @click="continueTo(false)"
                  :disabled="busy"
                  :color="accessAccountTitleStyleColor"
                  large
                  block
                  text
              >
                Agora não
              </v-btn>
            </v-card-actions>
          </v-flex>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import {getUseTerms, acceptUseTerms, refuseUseTerms, findUserByLogin, geoPlugin} from '@/common/securityserver';
import $store from "../../store/store";
import logo from "../../components/security/Header";

export default {
  name: 'Termos',
  components:{ logo },
  computed: {
    ...mapGetters(['user']),
    ...mapState([
      "enabledStepMode", "accessAccountTitleStyleColor",
    ]),
  },
  data: () => ({
    busy: false,
    termo: '',
    accept: {},
    validationChecksOK: true,
    userId: ''
  }),
  async beforeMount() {

    await getUseTerms()
        .then(response => {
          this.termo = response;
          var result = this.termo.templateConfiguration.filter(obj => {
            return obj.parameter === "JsonAccept"
          })
          if(result[0]) {
            var jsonAccept = result[0].value;
            this.accept = JSON.parse(jsonAccept);
            this.configAcceptChecks();
          }
        });

    await findUserByLogin($store.state.auth.login)
        .then(user => {
              this.userId =  user.id;
            }
        )

    this.loading(false);
  },
  methods: {
    ...mapMutations(["loading"]),
    continueTo(accept) {

      this.busy = true;

      var jsonAcceptRequest = {
        termId: this.termo.id,
        accept: this.accept,
        request: null,
      }

      var useTerms = {
        userId : this.userId,
        jsonAccept: null,
        requestAccept: null,
      };

      geoPlugin().then(geoRequest => {
        jsonAcceptRequest.request = geoRequest.data;
        useTerms.jsonAccept = JSON.stringify(jsonAcceptRequest);
        if (accept) {
          return acceptUseTerms(useTerms).then(response => {
            return this.$store.dispatch('serviceLogin', $store.state.auth).then((response) => {
              if (response.error) {
                if (response.text == 'ACCESS_VALIDATION_ERROR_MESSAGE') {
                  this.$router.push('/userNotEnableToAccess');
                }else{
                  this.$router.push('/serviceLogin');
                  this.$store.commit('showmsg', {text: response.text, type: "error"});
                }
              } else {
                this.$store.commit('token', response.access_token);
                this.$router.push('/serviceLoginCallback');
              }
              this.$store.commit('auth', {});
            })
          });
        }else{
          refuseUseTerms(useTerms).then( response => {
            this.$router.push('/serviceLogin');
            this.$store.commit('showmsg', { text: "Termo de uso recusado", type: "error"});
          })
        }
      }).catch(reason => {
        this.showmsg({
          text: "Erro ao aceitar o termo!",
          type: "error",
        });
      }).finally(() => {
        this.busy = false;
      });
    },
    configAcceptChecks(){
      for(const accept of this.accept.checks){
        if(accept.mandatory){
          this.validationChecksOK = false;
          if(accept.depends.length){
            for(const depend of accept.depends) {
              for (const acceptAux of this.accept.checks) {
                if (depend.id == acceptAux.id) {
                  acceptAux.mandatory = true;
                  break;
                }
              }
            }
          }
        }
      }
    },
    validateAcceptChecks(){
      if(this.accept && this.accept.checks && this.accept.checks.length){
        for(const accept of this.accept.checks){
          if(accept.mandatory){

            if(!accept.checked){
              this.validationChecksOK = false;
              return;
            }

            if(accept.depends.length)
              for(const depend of accept.depends)
                for(const acceptAux of this.accept.checks)
                  if(acceptAux.id == depend.id && !acceptAux.checked){
                    this.validationChecksOK = false;
                    return;
                  }

          }
        }
      }
      this.validationChecksOK = true;
    },
  },
};
</script>
