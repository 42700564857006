<template>
  <vue-recaptcha style="transform:scale(0.8);transform-origin:0;-webkit-transform:scale(0.8);
transform:scale(0.8);-webkit-transform-origin:0 0;transform-origin:0 0;" v-if="reload" language="pt-BR" :sitekey="siteKey" :loadRecaptchaScript="true" @verify="verify" @error="error" @expired="expired"/>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: "Recaptcha",
  components: { VueRecaptcha },
  props: {
    siteKey: {
      required: true,
      type: String,
    },
    reload: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    verify(value) {
      this.$emit('verify', value);
    },
    error() {
      this.$emit('error');
    },
    expired() {
      this.$emit('expired');
    },
  },
};
</script>
