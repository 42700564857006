<template>
  <v-container
    fluid
    fill-height
    :style="!!enabledStepMode ? { padding: 0 } : null"
  >
    <v-layout align-center justify-center wrap>
      <v-flex v-if="!enabledStepMode" xs12 sm6 md4 lg3>
        <Header/>
        <v-card :class="($resize && $mq.above(window.mobileSize) ? 'pa-5 mb-16' : '')" :style="'margin-bottom: 40px; --access-account-color: ' + this.accessAccountStyleColor">
          <v-card-text>
            <p class="access-account ">Redefinir senha</p>
            <p class="redefine-password-description">Inisira o e-mail relacionado a sua conta para realizar a redefinição de sua senha.</p>
            <v-text-field class="mt-7" name="email" label="E-mail" type="text" placeholder="Digite aqui seu e-mail" v-model="form.email" outlined/>
            <Recaptcha :siteKey="captchaSiteKey" @verify="verify" @error="invalidate" @expired="invalidate" :reload="true"/>
          </v-card-text>
          <v-card-actions>
            <v-btn block color="primary" height="48px" @click="redefinePassword()" :loading="busy" :disabled="canRegister">Redefinir</v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn block color="secondary" class="black--text" height="48px" @click="$router.go(-1)" :disabled="busy" >Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex :style="{ ...gradientBackgroundStyle }" v-if="enabledStepMode">
        <v-card
          :style="{ ...stepsBackgroundStyle, height: '100vh', width: '100vw' }"
        >
          <v-card-text>
            <p
              class="big-title-steps-mode mb-md-10"
              :style="'margin-top: 20px; --big-title-steps-color: ' + bigTitleStepStyle.color + '; --big-title-steps-font-size: ' + bigTitleStepStyle.fontSize"
            >
              Atualizar senha
            </p>

            <p
              class="small-title-steps-mode mb-md-10"
              :style="'margin-bottom: 5px; --small-title-steps-color: ' + smallTitleStepStyle.color + '; --small-title-steps-size: ' + smallTitleStepStyle.fontSize"
            >
              Insira o e-mail relacionado a sua conta para realizar a redefinição de sua senha.
            </p>

            <v-text-field
              class="mt-7"
              name="email"
              type="text"
              v-model="form.email"
              @keydown.space="(event) => event.preventDefault()"
              style="margin-bottom: 15px"
            />

            <Recaptcha :siteKey="captchaSiteKey" @verify="verify" @error="invalidate" @expired="invalidate" :reload="true"/>

          </v-card-text>

          <v-layout :style="$vuetify.breakpoint.width < 351 && { marginTop: '5%' }" align-end>
            <v-row align="end">
              <v-col class="pb-0" cols="12">
                <v-card-actions class="password-resize">
                  <v-btn
                    block
                    color="primary"
                    large
                    style="margin-bottom: 10px;"
                    class="login-btn-steps-mode"
                    @click="redefinePassword()"
                    :loading="busy"
                    :disabled="canRegister"
                  >
                    Enviar
                  </v-btn>
                </v-card-actions>
              </v-col>
              <v-col class="pt-0" cols="12">
                <v-card-actions>
                  <v-btn
                    @click="$router.go(-1)"
                    :color="accessAccountTitleStyleColor"
                    large
                    block
                    text
                  >
                    Voltar
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <Footer/>
  </v-container>
</template>

<script>
import { mask } from 'vue-the-mask';
import Store from "@/store/store";
import { forgotUserPassword} from '@/common/securityserver';
import Recaptcha from '@/components/recaptcha/index.vue';
import {mapState, mapMutations} from "vuex";
import Header from "../../components/security/Header";
import Footer from "../../components/security/Footer";

export default {
  name: "RedefinirSenha",
  components: { Recaptcha, Header, Footer },
  directives: { mask },
  data: () => ({
    invalidCredentials: true,
    informationsOpen: false,
    busy: false,
    showingPassword: false,
    passwordIsEnough: false,
    form: {
      email: "",
    },
  }),
  methods: {
    ...mapMutations(["showmsg"]),
    redefinePassword() {

      this.busy = true;
      forgotUserPassword(this.form).then(response => {
        if(response.error){
          this.$store.commit('showmsg', { text: 'Não foi possível redefinir a senha!', type: "error" });
        }else{
          const msg = `Enviamos um e-mail para você em ${this.form.email} com as instruções necessárias para redefinir sua senha.`;
          this.$store.commit('showmsg', { text: msg, type: "success" });
          this.$router.push('/serviceLogin');
        }
      }).catch(error => {
        if(error && error.response && error.response.data && error.response.data.message === 'Seus dados de login são inválidos para este aplicativo.'){
          this.showmsg({
            text: error.response.data.message,
            type: "error",
          })
        }else{
          this.showmsg({
            text: 'Não foi possível redefinir a senha!',
            type: "error",
          })
        }
      }).finally(() => this.busy = false);
    },
    verify() {
      this.invalidCredentials = false;
    },
    invalidate() {
      this.invalidCredentials = true;
    }
  },
  watch: {
    'form.email'(newValue) {
      if (newValue) {
        this.form.email = newValue.toLowerCase();
        this.form.email = this.form.email.replaceAll(' ', '');
      }
    }
  },
  computed: {
    ...mapState([
      "window", "enabledStepMode", "enabledStepMode", "accessAccountTitleStyleColor",
      "loginCardCustomStyle", "extraTitlesStyle", "bigTitleStepStyle",
      "smallTitleStepStyle", "stepsBackgroundStyle", "gradientBackgroundStyle",
    ]),
    isMobileSize() {
      return this.$resize && this.$mq.below(this.window.mobileSize);
    },
    captchaSiteKey(){
      return process.env.VUE_APP_CAPTCHA_SITE_KEY;
    },
    canRegister() {
      return this.invalidCredentials || this.form.email.length == 0;
    },
    accessAccountStyleColor(){

      let appCode = this.$store.state.appCode;

      if(appCode === 'eu_protegido'){
        return this.$vuetify.theme.themes.light.primary;
      }else if(appCode === 'rh_protegido'){
        return '#100d38';
      }else{
        return this.$vuetify.theme.themes.light.primary;
      }
    },
  },
  async beforeRouteEnter(to, from, next) {
    const logged = await Store.dispatch('checkAuthentication');

    if (logged) {
      next('/home');
    } else {
      next();
    }
  },
};
</script>

<style scoped>
  /* large */
  @media (min-height: 670px) and (min-width: 411px){
    .password-resize {
      margin-top: 150px;
    }
  }

  /* medium */
  @media (min-height: 700px) and (min-width: 350px) and (max-width: 410px){
    .password-resize {
      margin-top: 150px;
    }
  }

  /* small */
  @media (max-height: 699px) and (min-width: 350px) and (max-width: 410px){
    .password-resize {
      margin-top: 100px;
    }
  }
</style>
