<template>
  <v-container style="background: #292867 !important;" fluid grid-list-md>
  </v-container>
</template>

<script>

export default {
  name: 'Home',
  data: () => ({
  }),
};

</script>
