<template>
  <v-container class="contrastHard" fluid fill-height>
    <v-layout align-center justify-center wrap>
      <v-flex xs12 sm8 md4>
        <!-- <center>
          <img src="/img/icons/logo.jpg" style="width:150px" />
        </center> -->
        <v-card class="mt-5">
          <v-card-text>
            <v-form
              id="formlogin"
              ref="formlogin"
              @submit.prevent="login"
              autocomplete="on"
              :lazy-validation="true"
            >
              <v-text-field
                autofocus
                autocomplete
                clearable
                clear-icon="fas fa-times grey--text"
                prepend-inner-icon="fas fa-user grey--text"
                name="login"
                id="login"
                label="Usuário"
                placeholder=" "
                type="text"
                v-model="auth.login"
                :rules="loginrules"
                :validate-on-blur="false"
              />
              <v-text-field
                clearable
                clear-icon="fas fa-times grey--text"
                prepend-inner-icon="fas fa-lock grey--text"
                name="password"
                id="password"
                label="Senha"
                placeholder=" "
                :type="showpassword ? 'text' : 'password'"
                :append-outer-icon="
                  !showpassword
                    ? 'far fa-eye-slash grey--text'
                    : 'far fa-eye grey--text'
                "
                @click:append-outer="showpassword = !showpassword"
                v-model="auth.password"
                :rules="loginrules"
                :validate-on-blur="false"
                class="mt-3"
              />
              <Recaptcha :siteKey="captchaSiteKey" @verify="verify" @error="invalidate" @expired="invalidate" :reload="invalidCredentials"/>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-btn block color="primary" type="submit" form="formlogin" :loading="busy" :disabled="!!loginLocked">
                  Entrar
                  <v-icon right>fas fa-sign-in-alt</v-icon>
                </v-btn>
              </v-col>
              <v-col class="text-center py-0 my-0" sm="12" md="12" lg="16" xl="6">
                <v-btn to="/primeiro-acesso" block color="transparent grey--text" depressed>Criar Conta</v-btn>
              </v-col>
              <v-col class="text-center py-0 my-0" sm="12" md="12" lg="16" xl="6">
                <v-btn to="/redefinir-senha" block color="transparent grey--text" depressed>Esqueci Minha Senha</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import store from "@/store/store";
import { mapMutations } from "vuex";
import { formrules } from "./../../common/formrules";
import Recaptcha from '@/components/recaptcha/index.vue';

export default {
  name: 'Login',
  components: { Recaptcha },
  data: () => ({
    invalidCredentials: false,
    busy: false,
    loginrules: [],
    showpassword: false,
    auth: {
      login: "",
      password: ""
    },
  }),
  computed: {
    loginLocked() {
      const noFormData = !this.auth.login.length || !this.auth.password.length;
      if (this.invalidCredentials && !noFormData) {
        return true;
      }

      return noFormData;
    },
    captchaSiteKey(){
      return process.env.VUE_APP_CAPTCHA_SITE_KEY;
    }
  },
  methods: {
    ...mapMutations(["showmsg"]),
    login() {
      this.loginrules.push(formrules.required);

      if (!this.$refs.formlogin.validate()) {
        setTimeout(() => {
          this.loginrules.splice(0, this.loginrules.length);
          this.$refs.formlogin.resetValidation();
        }, 3000);
        return;
      }

      this.busy = true;
      this.invalidCredentials = false;
      this.$store.dispatch('login', this.auth)
        .then((response) => {
          if (response.error) {
            if (response.text === 'Acesso invalido') {
              this.invalidCredentials = true;
            }
            this.$store.commit('showmsg', { text: response.text, type: "error" });
          } else {
            this.$router.push('/home');
          }
        })
        .finally(() => this.busy = false);
    },
    verify() {
      this.invalidCredentials = false;
    },
    invalidate() {
      this.invalidCredentials = true;
    },
  },
  async beforeRouteEnter(to, from, next) {
    const logged = await store.dispatch('checkAuthentication');

    if (logged) {
      next('/home');
    } else {
      next();
    }
  },
};
</script>
