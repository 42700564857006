<style lang="scss">
  @import 'public/styles/app.scss';
</style>
<template>
  <v-app id="account" class="noScroll" :style="style" v-if="isLoaded">
    <v-layout
      justify-center
      align-center
      :style="!!enabledBgOpacity ? opacityStyle : null"
    >
      <msg />
      <Loading/>
      <router-view name="container"></router-view>
      <keep-alive>
        <router-view name="containerkeepalive"></router-view>
      </keep-alive>
    </v-layout>
  </v-app>
</template>

<script>
import msg from "./components/structure/msg";
import { mapGetters, mapState } from "vuex";
import Loading from "./components/structure/Loading";

export default {
  name: 'App',
  components: { Loading, msg },
  data: () => ({
    isLoaded: false,
  }),
  beforeCreate() {
    if (!sessionStorage.getItem('CACHE_CLEAR')) {
      window.location.reload();
      sessionStorage.setItem('CACHE_CLEAR' , 'true');
    }
  },
  created() {
    sessionStorage.setItem('SERVICE_URL', btoa(process.env.VUE_APP_SECURITY_SERVICE_URL));
    sessionStorage.setItem('APP_BUILD_VERSION', btoa(process.env.VUE_APP_BUILD_VERSION));
  },
  methods: {
    async handleSessionAuthentication() {
      const logged = await this.$store.dispatch('checkAuthentication');

      if (!logged && this.$route.meta.auth) {
        this.$store.dispatch('logout');
        this.$store.commit('updateMenuState', {
          left: false,
          leftmini: false,
          right: false,
        });
        this.$router.push('/login');
      }
    },
  },
  computed:{
    ...mapGetters(["style", "enabledBgOpacity", "opacityStyle"]),
    ...mapState(["enabledStepMode"])
  },
 watch: {
    $route() {
      this.handleSessionAuthentication();
    },
  },
  beforeMount() {
    this.handleSessionAuthentication();
  },
  mounted() {
    setTimeout(() => {
      this.isLoaded = true;
    }, 1000);
    this.handleSessionAuthentication();
  },
  beforeRouteEnter() {
    this.handleSessionAuthentication();
  },
};
</script>

<style>
.noScroll {
  overflow: hidden;
}
.removeBG{
  background-image: none !important;
}

.v-btn--disabled {
  pointer-events: unset !important;
  cursor: default;
}

.v-stepper, .v-stepper__items, .v-stepper__content, .v-stepper__wrapper {
  overflow: visible !important;
}
</style>
